import logo from './logo.svg';
import React from 'react';
import './App.css';
import {Line} from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'

const state = {
    labels: ['January', 'February', 'March',
             'April', 'May'],
    datasets: [
      {
        label: 'Rainfall',
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56]
      }
    ]
  }


export default class App extends React.Component {
  render() {
    return (
      <div>
        <div className="content-block light-purple-background full-width-background">
          <div className="small-section-header">INCLUSION OVER TIME</div>

          <div className="chart-container">
            <Line
              data={state}
              options={{
                title:{
                  display:true,
                  text:'Average Rainfall per month',
                  fontSize:20
                },
                legend:{
                  display:true,
                  position:'right'
                }
              }}
            />
          </div>

        </div>
        
      </div>
    );
  }
}

{/*function App() {
  

  return (
    <div className="App">

      

    </div>
  );
}


export default App;
*/}

